<template>
    <v-layout class="f-file-field">
        <v-flex class="text-column pa-0">
            <v-text-field
                    v-model="text"
                    ref="textField"
                    readonly
                    :disabled="disabled"
                    :label="label"
                    :placeholder="placeholder"
                    :prepend-icon="prependIcon"
                    :error-messages="errorMessages"
                    :box="box"
                    :outline="outline"
                    @keyup.enter="onkeyup"
                    @blur="onblur"
                    @click="selectFile"
            >
                <template slot="append">
                    <v-icon v-if="enableDownloadFile" @click="downloadFile">mdi-download</v-icon>
                    <v-icon v-if="enableRemoveFile" @click="removeFile">mdi-cancel</v-icon>
                </template>
            </v-text-field>
        </v-flex>
        <input type="file" ref="uploader" @change="fileSelected" :accept="accept">
    </v-layout>
</template>

<script>
    export default {
        name : 'f-file-field',
        props : {
            value         : {},
            label         : {},
            prependIcon   : {},
            errorMessages : {},
            box           : {},
            outline       : {},
            disabled      : {},
            settings      : {},
            accept        : {},
            removable     : {
                type : Boolean
            }
        },

        computed : {
            text() {
                if (this.settings && this.settings.format) {
                    return this.settings.format();
                }
                if (this.value && this.value.size) {
                    return this.formatBytes(this.value.size);
                }
            },
            url() {
                if (this.value) {
                    if (this.value.url) {
                        return this.value.url;
                    } else {
                        return this.value.data;
                    }
                }
                return '/images/noimage.png';
            },
            placeholder() {
                return this.$t('fude.form.icon-field.placeholder');
            },

            enableDownloadFile() {
                if (this.settings && this.settings.enableDownload) {
                    return this.settings.enableDownload();
                }
                return this.value && this.value.url;
            },

            enableRemoveFile() {
                return this.removable && this.value && (this.value.data || this.value.url);
            }
        },

        methods : {
            formatBytes(bytes, decimals) {
                if (bytes === 0) {
                    return '0 B';
                }
                let k = 1024;
                let dm = decimals <= 0 ? 0 : decimals || 2;
                let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
                let i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            },

            focus() {
                this.$refs.textField.focus();
            },

            onkeyup(e) {
                this.selectFile();
            },

            onblur(e) {
                this.$emit('blur', e);
            },

            selectFile() {
                if (this.settings && this.settings.beforeSelect) {
                    this.settings.beforeSelect().then(() => {
                        this.$refs.uploader.click();
                    }).catch((error) => {
                        console.log('error', error);
                    })
                } else {
                    this.$refs.uploader.click();
                }
            },

            fileSelected($event) {
                let files = $event.target.files || $event.dataTransfer.files;
                let file = files && files[0];
                if (!file) {
                    return;
                }

                let reader = new FileReader();
                reader.onload = () => {
                    this.$emit('input', {
                        file : file,
                        size : file.size,
                        data : reader.result
                    });
                    this.$emit('next');
                };
                reader.readAsDataURL(file);
            },

            downloadFile() {
                let win = window.open(this.value.url, '_blank');
                win.focus();
            },

            removeFile() {
                let value = (this.value && this.value.url)
                    ? {
                        file : new File([''], '__REMOVE__')
                    }
                    : null;
                this.$emit('input', value);
            }
        }
    }
</script>

<style lang="less">
    .f-file-field {
        cursor: pointer;
        input[type=text] {
            cursor: pointer;
        }
        input[type=file] {
            display: none;
        }
        .v-text-field__slot {
            cursor: pointer;
        }
    }
</style>