import Plugin from "../plugin";
import Vue from 'vue';

const ON_LOAD  = '$onLoad';
const ON_ERROR = '$onError';

class RemoteData {
    constructor(plugin, vm, handlers) {
        this._plugin = plugin;
        this._vm = vm;

        this._onLoad  = handlers[ON_LOAD]  || Plugin.noop;
        this._onError = handlers[ON_ERROR] || Plugin.noop;

        this._handlers = Object.entries(handlers)
            .filter(([name]) => ![ON_LOAD, ON_ERROR].includes(name))
            .map(([name, handler]) => ({ name, handler }));

        this._handlers.forEach(({ name }) => {
            Vue.util.defineReactive(this._vm, name, null);
        });
        this._loaded = null;
    }

    async load() {
        if (!this._loaded) {
            this._loaded = new Promise(async(resolve, reject) => {
                try {
                    let data = {};
                    for (let i = 0; i < this._handlers.length; i++) {
                        let { name, handler } = this._handlers[i];
                        data[name] = await this._plugin.load(handler, this._vm);
                    }
                    for (let i = 0; i < this._handlers.length; i++) {
                        let { name } = this._handlers[i];
                        this._vm[name] = data[name];
                    }
                    this._loaded = true;
                    this._onLoad.apply(this._vm);
                    resolve();
                } catch (error) {
                    console.log('error', error);
                    this._onError.apply(this._vm, error);
                    reject();
                }
            });
        }
        return this._loaded;
    }
    async reload() {
        this._loaded = null;
        await this.load();
    }
}

export default RemoteData;