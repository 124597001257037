export default {
    data() {
        return {
            errorMessage : null
        }
    },
    methods : {
        $setError(error) {
            if (error) {
                console.log('error', error);
                this.errorMessage = this.$getErrorMessage(error);
            } else {
                this.errorMessage = null;
            }
            return this.errorMessage;
        },
        $getErrorMessage(error) {
            let result = null;
            if (error) {
                if (error.response && error.response.data) {
                    result = error.response.data;
                } else {
                    result = error.message || error;
                }
            }
            if (result) {
                result = result.replace(/^error\s*:/, '');
                if (result.indexOf('errors.') === 0) {
                    result = this.$t(result);
                }
            }
            return result;
        }
    }
}